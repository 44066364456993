.contact{
    color: #013220;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 90vh;
}

.contact-heading{
    height: 15vh;
    margin-top: 70px;
    width: 70%;
}

.contact-heading :first-child{
    font-size: 20px;
}

.contact-heading :last-child{
    margin-top: 20px;
    font-size: 40px;
    font-weight: bolder;
}

.contact-main{
    margin-top: 50px;
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}

.contact-main form{
    width: 40%;
}

.contact-main form input{
    margin-bottom: 20px;
    min-height:60px;
    width: 100%;
    font-size: 1.2rem;
    white-space: normal;
    padding: 10px;
}

.contact-main form textarea{
    padding: 10px;
}

#message{
    font-size: 1.1rem;
    width: 100%;
    height: 120px;
}

#message::placeholder{
    font-size: larger;
}


.contact-info p{
    text-decoration: underline;
    font-size: 1.2rem;
    font-weight: 600;
}

.ending p{
    text-decoration: none;
}

button{
    padding: 10px 30px;
    background-color: #013220;
    color: white;
    outline: none;
    border-radius: 5px;
    font-size: 15px;
}


@media (max-width:1039px) {
    .contact-heading{
        width: 95vw;
    }
    .contact-main{
        flex-direction: column;
        width: 95vw;
    }

    .contact-main form{
        width: 90%;
    }
}
