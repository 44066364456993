.portfolio{
    background-color: #f3f9ff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #013220;
}

.portfolio-heading{
    height: 15vh;
    margin-top: 70px;
}

    
.portfolio-heading :first-child{
    font-size: 20px;
}

.portfolio-heading :last-child{
    margin-top: 20px;
    font-size: 40px;
    font-weight: bolder;
}

.projects{
    display: flex;
    flex-wrap: wrap;
    height: auto;
}

a {
    text-decoration: none;
    color: inherit;
    font-size: 20px;
    font-weight: bold;
}


.project{
    border-radius: 3px;
    background-color: white;
    border: 1px solid rgba(30, 30, 30, 0.2);
    margin: 0px 10px 10px 0px;
    padding-left: 30px;
    padding-right: 10px;
    padding-bottom: 10px;

    display: flex;
    flex-direction: row;
    flex-direction: column;
    justify-content: space-between;
}

.tech{
    font-weight: 600;
}

@media (min-width:1295px) {

    .portfolio-heading{
        width: 70%;
    }

    .projects{
        width: 70%;
    }
    
    .projects *.project{
        width: 26%;
        min-height: 20vh;
        height: auto;
    }
}

@media (min-width:1040px) and (max-width: 1294px) {

    .portfolio-heading{
        width: 85%;
    }

    .projects{
        width:85%;
    }

    .projects *.project{
        width: 26%;
        min-height: 25vh;
        height: auto;
    }
}



@media (max-width:1039px) {
    .portfolio-heading{
        width: 95vw;
    }

    .projects{
        width: 95vw;
    }

    .projects *.project{
        width: 35%;
        min-height: 20vh;
        height: auto;
    }
}

@media (max-width:550px) {
    .projects *.project{
        min-width: 80%;
        min-height: 20vh;
        height: auto;
    }
}