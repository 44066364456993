.about{
    color: #013220;
}


@media (min-width:1040px) {
    .about{
        min-height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .about-top{
        display: flex;
        height: 30vh;
        width: 70%;
    }
    
    
    
    .about-bottom{
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items:flex-start;
        width: 70%;
        flex-wrap: wrap;
    }
    
    
    
    .about-bottom > div{
        width: 30%;
        min-height: 15vh;
        text-align: center;
        transition: all 0.4s; 
        border-radius: 5px;
    }
    
    .greet{
        width: 40%;
    }
    .greet :first-child{
        font-size: 1.2rem;
    }
    
    .greet :last-child{
        font-size: 1.5rem;
    }
    
    .about-bottom > div:hover {
        transform: translate(-10px, -10px);
        box-shadow: 5px 0 5px -5px rgba(0,0,0,0.3), 
                    0 5px 5px -5px rgba(0,0,0,0.3);
    }
    
    
    
}

.about-intro{
    font-size: 1.1rem;
}
.about-intro span{
    color: #9EB384;
}

.about-contact{
    display: flex;
}

.about-resume{
    padding-top: 15px;
}

.about-resume a{
    margin-top: 20px;
    font-weight: normal;
    text-decoration: underline;
    font-size: 1rem;
}
.about-contact p{
    margin-right: 20px;
}

.about-contact p:first-child{
    text-decoration: underline;
}

.about-contact p:last-child{
    font-weight: bold;
    font-size: large;
}

.test{
    background-color: #d3f4ec;
}

.about-box{
    background-color: antiquewhite;
}

.experience-items{
    white-space: normal;
}

.experience-item{
    background-color: white;
    width: auto;
    height: auto;
    padding: 5px;
    border-radius: 5px;
    float: left;
    display: inline;
    margin: 10px;
}

.experience-item p{
    display: inline;
    font-weight: 500;
}


@media (max-width:1039px) {
    .about{
        margin: 80px 20px 20px 20px;
    }
    .about-intro p{
        display: inline; 
    }

    .about-bottom{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;
    }
    
    
    
    .about-bottom > div{
        width: 47%;
        min-height: 15vh;
        text-align: center;
        border-radius: 10px;
        margin: 7px;
    }
}

@media (max-width:506px) {
    .about{
        margin: 80px 20px 20px 20px;
    }
    .about-intro p{
        display: inline; 
    }

    .about-bottom{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .about-contact{
        display: flex;
        flex-direction: column;
    }
    
    
    
    .about-bottom > div{
        width: 100%;
        min-height: 15vh;
        text-align: center;
        border-radius: 10px;
        margin: 7px;
    }
}