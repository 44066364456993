

.home{
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2faeb;
  flex-wrap: wrap-reverse;
}

/* @media (min-width:741px) {
  
} */
/* .logo{
  float: right;
} */

.intro{
  min-width: 370px;
  margin-right: 100px;
  color: #013220;
  font-size: 1.2rem;
  line-height: 0.8;
}

.intro span{
  /* color: #8bba9f */
  color: #7eb19a
}

.typing span{
  position: relative;
}

.typing span::before{
  content: "";
  position: absolute;
  width:2px;
  height:30px;

  background-color: #a5a6ff;

  right: -8px;
  top: 51%;

  transform: translateY(-45%);

  animation: blink 0.7s infinite;
}

.typing span.stop-blinking::before {
  animation: none;
}

@keyframes blink {
  50%{
      opacity: 0;
  }
}

.logo img{
  height: 30vh;
  border-radius: 30px;
}

@media  (max-width:740px) {
  .home{
    flex-direction: column-reverse;
    flex-wrap: wrap-reverse;
    align-items: start;
  }

  .intro{
    margin: 0px 0px 0px 10px;
    min-width: none;
  }

  .logo{
    margin: 0 0 15px 10px;
  }

}

