
.sidebar{
  position: fixed;
  display: flex;
  color: #013220;
  z-index: 1;
}

.navigator li{
  cursor: pointer;
}

@media (min-width: 1400px) {
  .sidebar{
    height: 100vh;
    width: 290px;
    border-right: 1px solid gray;
    grid-column-start: 1;
    flex-direction: column;
    justify-content: space-between;
    
  }
  
  .profile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .profile-name{
    position: relative;
    font-style: italic;
    font-size: 35px;
    line-height: 1;
    color: rgba(19, 15, 73, 0.07);
    display: block;
  }
  
  .profile-name span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-weight: 700;
    font-size: 30px;
    color: #013220;
    margin-top: 5px;
    font-size: 18px;
    white-space: nowrap;
  }
  
  .my-footer{
    display: flex;
    flex-direction: inherit;
    justify-content: center;
    align-items: center;
  }
  
  
  .navigator{
    text-align: center;
    list-style-type: none;
    margin-top: 0;
    padding: 0;
  }
  
  .navigator li{
    margin-top: 10px;
  }
}

@media (max-width:1399px) {

  .sidebar{
    min-width: 100vw;
    position: fixed;
    display: flex;
    background-color: white;
    justify-content: center;
  }



  .navigator{
    display: flex;
    text-align: center;
    list-style-type: none;
    gap : 1rem;
    padding: 0;
  }


  /* .navigator li{
    margin-left: 1rem;
  } */

  .my-footer{
    display: none;
  }

  .profile{
    display: none;
  }
  
}
