/* body{
  background-color: antiquewhite;
} */

@media (min-width:1400px) {
  .app{
    display: grid;
    grid-template-columns: 290px 1fr;
  }
}



